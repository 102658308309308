
<template>
    <div>
        <a href="" class="downloadable-link-tag"></a>
        <div class="dF jE mt-3" style="padding-right: 24px;">
            <MoreActions v-if="selectedRowKeys.length && editingRights" shape="button" buttonText="Bulk Actions" :items="bulkActionsFiltered" @action="bulkActioned"/>
        </div>
        <a-table :pagination="false" :rowKey="e => e.id" :row-selection="rowSelection" :columns="columns" :data-source="dataTransformed" class="white-table" >
            <template slot="name" slot-scope="record" >
                <template v-if="main && record.type === 'regular'">
                    <a-icon v-if="currentTransaction.isManual" type="highlight" class="mr-2 text-danger" />
                    <a-icon v-else type="file-protect" class="mr-2 text-danger" />
                    <span>{{currentTransaction.type === 'lease' ? 'Main Lease Agreement' : tableFor === 'reservation' ? 'Main Reservation Agreement' : 'Main Purchase Agreement'}}</span>
                    <em class="ml-1">{{currentTransaction.isManual ? '(Print & Sign)' : '(E-Signature)'}}</em>
                </template>
                <template v-else>
                    <Icons type="contract-file" style="width:10px;color:var(--red);" class="mr-2" />
                    <span>{{record.name}}</span>
                </template>
            </template>
            <template slot="owner" slot-scope="record" >
                <div class="dF aC">
                    <a-avatar :size="25" :src="record.owner.avatar" :icon="record.owner.avatar && record.owner.avatar.trim() ? '' : 'user'" class="mr-2" /> {{record.owner.firstName}} {{record.owner.lastName}}
                </div>
            </template>
            <template slot="progress" slot-scope="record" >
                <span v-if="record.status && record.status.includes('void')" class="text-danger">VOIDED</span>
                <template v-else-if="record.isManual">
                    <span v-if="record.status === 'completed'"><a-icon type="check" class="mr-2 text-success" /> Completed</span>
                    <span v-else>Pending upload</span>
                </template>
                <a-tooltip overlayClassName="change-tooltip-color" v-else-if="record && record.progress && record.progress.length">
                    <template slot="title">
                        <div>
                            <p v-for="(rec,recI) in record.progress" :key="rec.username + recI" class="dF aC">
                                <a-icon :type="rec.icon || 'dash'"  class="mr-2"/>
                                <span class="f1">
                                    {{rec.username}} ({{ rec.roleName }})
                                    <small class="block" v-if="rec.status === 'completed'">
                                        {{completedTypeLookup[rec.type] || 'Completed'}}
                                    </small>
                                    <small class="block" v-else>
                                        {{typeLookup[rec.type] || 'In Queue'}}
                                    </small>
                                </span>
                            </p>
                        </div>
                    </template>

                    <span v-if="record.signedSoFar === record.signFull">
                        <a-icon type="check" class="mr-2 text-success" /> Completed
                    </span>
                    <span v-else-if="record.signedSoFar >= 0">
                        <a-icon type="user" class="mr-2" />{{record.signedSoFar}} / {{record.signFull}} Signed
                    </span>

                </a-tooltip>
                <span v-else>
                    <span v-if="record.status === 'completed'"><a-icon type="check" class="mr-2 text-success" /> Completed</span>
                    <span v-else-if="record.status === 'draft'"><a-icon type="file-text" class="mr-2" /> Draft</span>
                    <span v-else>Delivered</span>
                </span>


            </template>
            <template slot="created" slot-scope="record" >
                {{formatDate(record.createdAt)}}
            </template>
            <template slot="action" slot-scope="record">
                <template v-if="editingRights">
                    <template v-if="currentTransaction.status === 'void' && transactionFileKey(record)">
						<div class="dF aC" style="gap: 20px">
							<a-button @click="downloadFile(record)" type="primary" ghost icon="download" size="small">{{record.isManual? 'SIGNED PDF' : 'SIGNED ZIP'}}</a-button>
							<a-button @click="previewFile(record)" type="primary" ghost icon="eye" size="small">Preview</a-button>
						</div>
                    </template>
                    <template v-else-if="record.status === 'completed'">
						<div class="dF aC" style="gap: 20px">
							<a-button @click="downloadFile(record)" type="primary" ghost icon="download" size="small">{{record.isManual? 'SIGNED PDF' : 'SIGNED ZIP'}}</a-button>
							<a-button @click="previewFile(record)" type="primary" ghost icon="eye" size="small">Preview</a-button>
							<a-button v-if="!requiredMutualRelease" @click="reservationCancellation.reason = ''; voidModal = true" type="primary" ghost icon="scissor" size="small">Void</a-button>
						</div>
                    </template>
                    <template v-else-if="docsOnly || record.status !== 'completed' ">
                        <MoreActions :items="moreActionFiltered(record)" :val="record" @action="action"/>
                    </template>
                </template>
            </template>
        </a-table>

		<a-modal :visible="inPersonSigning.showModal" @cancel="closeInPersonSigning" :centered="true" title="In Person Signing"
			okText="Process" @ok="processInPersonSigning">
			<a-form-model ref="inPersonSigning" :model="inPersonSigning" class="profile" v-if="inPersonSigning.envelope">
				<a-row type="flex" :gutter="20">
					<a-col :span="24">
						<a-form-model-item label="Select Signer" required
							:rules="[{ required: true, message: 'Please select signer first' }]" prop="signer">
							<a-select v-model="inPersonSigning.signer" size="large">
								<a-select-option v-for="(signer, index) in validInPersonSigners" :key="index" :value="signer.email" :title="`${ signer.name } (${ signer.role }) - ${ signer.email }`">
									{{ signer.name }} ({{ signer.role }}) - {{ signer.email }}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-alert v-if="!validInPersonSigners.length" type="error"
					message="No valid available Signers found for the current contract template."
					show-icon />
				<a-alert v-else type="warning" show-icon>
					<template #message>
						For contracts with both e-signatures and multiple in-person signees,
						Please upload ONLY the version that includes ALL THE SIGNATURES.
						There's NO NEED to Upload the version with just E-Signatures.
					</template>
				</a-alert>
			</a-form-model>
		</a-modal>
		<a-modal :visible="voidModal" @cancel="voidModal = false;" :centered="true"
			title="Void Reservation" okText="VOID" @ok="voidReservation" :confirmLoading="loading">
			<a-form-model ref="reservationCancellation" :model="reservationCancellation" class="profile">
				<p>Are you sure you want to void reservation of <strong>{{ reservationName }}</strong>?</p>
				<div>Doing this will void this entire reservation <br/>This will void any documents that are pending to be signed and remove any supporting documents and also removes any restrictions from the <b>underlying asset</b> and revert it's status to <b><em>Available</em></b>.</div>
				<a-row type="flex" :gutter="20" class="mt-3">
					<a-col :span="24">
						<a-form-model-item label="Reservation Cancellation Reason"
							:rules="req('Please enter reservation cancellation reason')" prop="reason">
							<a-textarea size="large" v-model="reservationCancellation.reason"
								placeholder="Reservation Cancellation Reason" :rows="3" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
    </div>

</template>

<script>

import Icons from '@/components/common/BHIcons'
import MoreActions from 'bh-mod/components/common/MoreActions'
import {formatDate,downloadURL} from 'bh-mod'
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from 'axios'

function uploadFile(callback){
    var input=document.createElement('input');
    input.type="file";
    input.className = 'bhh-file'
    input.style.display="none"
    input.onchange = (e) => {
        let file = e.target.files[0];
        callback(file)
        setTimeout(function(){
            input.remove()
        },100);
    }
    document.body.appendChild(input);
    setTimeout(function(){
        input.click();
    },100);

}
export default {
	props: {
		docsOnly: {
			type: Boolean,
			default: false
		},
		amendmentDocs: {
			type: Boolean,
			default: false
		},
		main: {
			type: Boolean,
			default: false
		},
		tableFor: {
			type: String,
			default: ''
		}
	},

    components:{Icons,MoreActions,},
    data(){
        return {
            dataTransformed:[],
            updatedEnvelopes:{},
            deletedEnvelope:[],
            typeLookup:{
                cc:'Carbon Copy',
                sign:'Signer',
                agent:'Agent',
            },
            completedTypeLookup:{
                cc:'Received Copy',
                sign:'Signed',
            },
			bulkActions: [
				{
					label: 'Void Documents',
					value: 'void'
				},
				{
					label: 'Delete Documents',
					value: 'delete'
				},
				{
					label: 'Download Documents',
					value: 'download'
				}
			],
            selectedRowKeys:[],
            selectedRows:[],
            moreActions:[
                {
                    label:'Download Signed Package (.zip)',
                    value:'download',
                    icon:'fe-download',
                    type:'envelope',
                    completed:true,
                },
				{
					label: 'Download',
					value: 'downloadDoc',
					icon: 'fe-download',
					type: 'doc',
				},
				{
					label: 'Print',
					value: 'printDoc',
					icon: 'printer',
					type: 'doc',
				},
                {
                    label:'Resend',
                    value:'resend',
                    icon:'retweet',
                    type:'envelope',
					void:true,
                    isManual:false
                },
                {
                    label:'Download Contract',
                    value:'downloadManual',
                    icon:'fe-download',
                    type:'envelope',
                    isManual:true,
                    status:'created',
                },
                {
                    label:'Upload Signed Contract',
                    value:'uploadSigned',
                    icon:'fe-upload',
                    type:'envelope',
                    isManual:true,
                    status:'created',
                },
                {
                    label:'Void',
                    value:'void',
                    icon:'fe-scissors',
                    type:'envelope',
                    void:true,
                    main:false,
                },
				{
                    label:'Continue Editing',
                    value:'continueEditing',
                    icon:'edit',
					draft: true
                },
                {
                    label:'Delete',
                    value:'delete',
                    icon:'fe-trash',
                    type:'doc',
					draft: true
                },
                {
                    label:'Void Transaction',
                    value:'voidTransaction',
                    icon:'fe-scissors',
                    type:'envelope',
                    void:true,
                    main:true,
					isReservation: false
                },
				{
                    label:'Void Reservation',
                    value:'voidReservation',
                    icon:'fe-scissors',
                    type:'envelope',
                    void:true,
                    main:true,
					isReservation: true
                },
				{
                    label:'Process In Person Signing',
                    value:'inPersonSigning',
                    icon:'man',
					type:'inPersonSigning'
                },
				{
                    label:'Preview',
                    value:'preview',
                    icon:'fe-eye',
                    type:'all',
                },
            ],
			inPersonSigning: {
				envelope: null,
				signer: null,
				showModal: false
			},
			loading: false,
			voidModal: false,
			reservationCancellation: {
				reason: ''
			},
			zip: new JSZip()
        }
    },
    watch:{
        'currentTransaction.envelopes':{
            deep:true,
            handler(){
                this.transformData()
            }
        },
        deletedEnvelope:{
            handler(){
                this.transformData()
            }
        },
        updatedEnvelopes:{
            handler(){
                this.transformData()
            }
        },
    },
    computed: {
		instance() {
			return this.$store.state.instance;
		},
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		reservationName() {
			if(!this.reservation) {
				return ''
			}
			if (this.instance.productType == 'highrise') {
				if (this.reservation.product && this.reservation.product.floor && this.reservation.product.unit) {
					return `${this.reservation.product.floor.name} (#${this.reservation.product.unit.unitNumber})`
				} else if (this.reservation.product && this.reservation.product.unit) {
					return 'Unit: ' + (this.reservation.product.unit.name || 'N/A')
				} else {
					return 'Unit N/A'
				}
			} else {
				if (this.reservation.product && this.reservation.product.lot) {
					return `${this.reservation.product.lot.address && (this.reservation.product.lot.address + ' - ') || ''}Lot ${this.reservation.product.lot && this.reservation.product.lot.name}  (${this.reservation.product.unit && this.reservation.product.unit.unitGroup && this.reservation.product.unit.unitGroup.name} ${this.reservation.product.unit && this.reservation.product.unit.name} - ${this.reservation.product.unit && this.reservation.product.unit.unitGroup && this.reservation.product.unit.unitGroup.size || ''}' ${this.reservation.product.unit && this.reservation.product.unit.unitGroup && this.reservation.product.unit.unitGroup.type || ''})`
				} else if (this.reservation.product && this.reservation.product.unit) {
					return 'Unit: ' + (this.reservation.product.unit.name || 'N/A')
				} else {
					return 'Lot N/A'
				}
			}
		},
		requiredMutualRelease() {
			return this.$store.state.appData.requiredMutualRelease
		},
        envelopes(){
			let envelopes = []
			if (this.tableFor === 'reservation' && this.reservation && this.reservation.id) {
				envelopes = this.reservation.envelopes
			} else {
				envelopes = this.currentTransaction.envelopes
			}
            if (this.docsOnly) return envelopes.filter(x => !x.esign)
            return envelopes.filter(x => x.esign || x.isManual)
        },

        isFulfillment(){
            return this.dataTransformed.some(env => {
                return env.type && env.type === 'fulfillment' && env.status !== 'voided'
            })
        },
        isRelease(){
            return this.dataTransformed.some(env => {
                return env.type && env.type === 'release' && env.status !== 'voided'
            })
        },
        editingRights(){
            let canEdit = false
            if (this.$p >= 40){
                return true
            } else {
                this.currentTransaction.owners.forEach( x => {
                    if (x.id == this.user.id){
                        canEdit = true
                    }
                })
                return canEdit
            }
        },
        user(){
            return this.$store.state.user.user
        },
        currentTransaction(){
			if (this.tableFor === 'reservation' && this.reservation && this.reservation.id) {
				return {...this.reservation, owners: [this.reservation.createdBy]}
			} else {
				return this.$store.state.appData.currentTransaction
			}
        },
        columns(){

            let columns = [
                    {
                        title: 'Document Name',
                        key: 'name',
                        scopedSlots: { customRender: 'name' },
                    },

                    {
                        title: 'Owner',
                        key: 'owner',
                        sign:true,
                        scopedSlots: { customRender: 'owner' },
                    },

                    {
                        title: 'Created',
                        key: 'created',
                        scopedSlots: { customRender: 'created' },
                    },
                    {
                        title: 'Progress',
                        key: 'progress',
                        sign:true,
                        scopedSlots: { customRender: 'progress' },
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        scopedSlots: { customRender: 'action' },
                    },



            ]

            columns = columns.filter(item => {
                if (item.hasOwnProperty('main')) return item.main === this.main
                if (this.docsOnly) return !item.sign
                return true
            })

            return columns
        },

		rowSelection() {
			if (!this.docsOnly && !this.amendmentDocs) return null
			if (!this.editingRights || this.currentTransaction.status === 'void') return null
			const { selectedRowKeys } = this;
			return {
				selectedRowKeys,
				onChange: this.onSelectChange,
				hideDefaultSelections: true,
				onSelection: this.onSelection,
				getCheckboxProps: record => ({
					props: {
						disabled: record.name === 'Disabled User', // Column configuration not to be checked
						name: record.name,
					},
				}),
			}
		},
		bulkActionsFiltered() {
			let result = this.bulkActions.filter(mA => {
				if (mA.value == 'delete') {
					return this.$p >= 40 && this.docsOnly
				} else if (mA.value == 'download') {
					return this.$p >= 40
				} else if (mA.value == 'void') {
					let valid = false
					this.envelopes.forEach(x => {
						if (!x.status) {
							valid = false;
							return valid;
						} else if (x.status.toLowerCase() == 'completed' || x.status.toLowerCase() != 'voided') {
							valid = false;
							return valid
						} else {
							if (x.status.toLowerCase() !== 'completed' && x.status.toLowerCase() != 'voided') {
								valid = true;
							}
						}
					})
					return valid
				}
			})
			return result
		},

		validInPersonSigners() {
			let singers = []

			if (this.inPersonSigning.envelope?.signers?.length) {
				this.inPersonSigning.envelope.signers.forEach(s => {
					s.signers.forEach(ss => {
						if (ss.wantToSignInPerson) {
							singers.push(ss)
						}
					})
				})
			}
			return singers
		},
		reservationEnvelops(){
			if(this.currentTransaction.reservation){
				return this.currentTransaction.reservation && this.currentTransaction.reservation.envelops
			} else if(this.tableFor === 'reservation' && this.reservation && this.reservation.id){
				return this.reservation.envelopes
			}
			return []
		}
    },
    methods:{
		req: (msg, required = true) => ({ required: required, message: msg }),

        transformData(){
            const filterTransactions = x => {
                if (this.deletedEnvelope && this.deletedEnvelope.includes(x.id)) return false

                let pass = true
                if (this.main){
                    if ( (!x.main) && (x.type && x.type === 'regular') ) pass = false
                } else {
                    if (x.main || x.type && x.type !== 'regular') pass = false
                }
                return pass
            }

			if (this.tableFor === 'reservation') {
				return this.transformReservationData();
			}

            let result = []
			if (this.currentTransaction.offer && !this.currentTransaction.offer.addOnDeposits && this.tableFor === 'addOns') {
				return result;
			}
            this.envelopes.filter(filterTransactions).forEach(item => {
				let envelope = item
				if (this.updatedEnvelopes[item.id]) envelope = this.updatedEnvelopes[item.id]

				envelope.signedSoFar = 0
				envelope.signFull = 0;
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					const recipients = envelope.envStatus && envelope.envStatus.data && envelope.envStatus.data.envelopeSummary && envelope.envStatus.data.envelopeSummary.recipients;
					signers = recipients && (recipients.signers || []).concat(recipients.carbonCopies || []).concat(recipients.intermediaries || []);

				} else {
					let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
					signers = recipients && recipients.signers || [];
				}

				if (signers && signers.length) {
					const progress = [];

					signers.forEach(s => {
						let username = s.name;
						let status = s.status === 'created' ? 'pending' : s.status === 'delivered' ? 'opened' : s.status;
						let type = s.recipientType === 'signer' ? 'sign' : s.recipientType === 'carboncopy' ? 'cc' : s.recipientType === 'agent' ? 'agent' : s.recipientType.toLowerCase();
						let icon = s.status === 'sent' ? 'mail' : s.status === 'completed' ? 'check' : s.status === 'delivered' ? 'read' : s.status === 'created' ? 'loading' : '';
						let roleName = s.roleName;

						if (type === 'sign') {
							envelope.signFull++
							if (status === 'completed') {
								envelope.signedSoFar++
							}
						}

						progress.push({ username, status, type, icon, roleName })
					})

					envelope.progress = progress;
				}
				result.push(envelope)
			})
            this.dataTransformed = result
        },

		transformReservationData() {
			let result = []
			this.reservationEnvelops.forEach((item) => {
				let envelope = item
				if (this.updatedEnvelopes[item.id]) envelope = this.updatedEnvelopes[item.id]

				envelope.signedSoFar = 0
				envelope.signFull = 0;
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					const recipients = envelope.envStatus && envelope.envStatus.data && envelope.envStatus.data.envelopeSummary && envelope.envStatus.data.envelopeSummary.recipients;
					signers = recipients && (recipients.signers || []).concat(recipients.carbonCopies || []).concat(recipients.intermediaries || []);

				} else {
					let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
					signers = recipients && recipients.signers || [];
				}

				if (signers && signers.length) {
					const progress = [];

					signers.forEach(s => {
						let username = s.name;
						let status = s.status === 'created' ? 'pending' : s.status === 'delivered' ? 'opened' : s.status;
						let type = s.recipientType === 'signer' ? 'sign' : s.recipientType === 'carboncopy' ? 'cc' : s.recipientType === 'agent' ? 'agent' : s.recipientType.toLowerCase();
						let icon = s.status === 'sent' ? 'mail' : s.status === 'completed' ? 'check' : s.status === 'delivered' ? 'read' : s.status === 'created' ? 'loading' : '';
						let roleName = s.roleName;

						if (type === 'sign') {
							envelope.signFull++
							if (status === 'completed') {
								envelope.signedSoFar++
							}
						}

						progress.push({ username, status, type, icon, roleName })
					})

					envelope.progress = progress;
				}
				result.push(envelope)
			})
			this.dataTransformed = result
		},

        async downloadFile(record, index = 0) {
			this.$store.commit('LOAD', true)
			let key
			if (record.isManual) {
				index = record.file && record.file.length ? (record.file.length - 1) : index;
				if (!record.file || !record.file[index] || !record.file[index].key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[index].key
			} else {
				if (!record.download || !record.download.archive || !record.download.archive.key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download.archive.key
			}

			try {
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`)
				await downloadURL(url)
			} catch (err) {
                this.$toastError(err, 'There was an error downloading the file. Please try again')
			}
			this.$store.commit('LOAD', false)
		},

		async previewFile(record, index = 0) {
			this.$store.commit('LOAD', true)

			index = record.file && record.file.length ? (record.file.length - 1) : index;
			let key = record.file && record.file[index] && record.file[index].key
			if (record.isManual) {
				if (!record.file || !record.file[index] || !record.file[index].key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[index].key
			} else {
				try {
					let { data } = await this.$api.get(`/envelops/:instance/${record.id}/download`);
					record.download = data;
				} catch (error) {
					console.error('Error while fetching latest contract template')
				}

				if ((!record.download || !record.download.combined || !record.download.combined.key) && !key) {
					this.$store.commit('LOAD', false)
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download && record.download.combined && record.download.combined.key || key
			}

			try {
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`)
				this.$store.commit('SHOW_PREVIEW', url)
			} catch (err) {
                this.$toastError(err, 'There was an error downloading the file. Please try again')
			}
			this.$store.commit('LOAD', false)
		},

		transactionFileKey(record, index = 0){
			let key
            if (record.isManual){
				index = record.file && record.file.length ? (record.file.length - 1) : index;
                if (record.file && record.file[index] && record.file[index].key ) key = record.file[index].key
            } else {
                if (record.download && record.download.archive && record.download.archive.key ) key = record.download.archive.key
            }

			return key;
		},

        moreActionFiltered(env){
            return this.moreActions.filter(mA => {

				if(env.status === 'draft') return mA.draft;
                if (mA.type === 'all') return true
                else if (mA.type === 'doc') return !env.esign && !env.isManual
				else if (mA.type === 'inPersonSigning') return env.status !== 'completed' && this.checkSignInPerson(env.signers)
                else if (mA.type === 'envelope'){
                    if (!env.status) env.status = 'created'
                    if (!env.esign && !env.isManual) return false
                    if (mA.completed === true) return env.status === 'completed'
                    if (mA.completed === false) return env.status !== 'completed'
                    if (mA.void) {
                        if (env.status && (env.status.toLowerCase() === 'completed' || env.status.toLowerCase() === 'voided')) return false
                    }

                    if ((mA.hasOwnProperty('main') && env.main !== mA.main) || (mA.main && mA.isReservation != !!this.$route.meta.reservation)) {
                        return false
                    }
                    if (mA.hasOwnProperty('isManual') && mA.isManual !== Boolean(env.isManual) ) {
                        return false
                    }
                    if (mA.hasOwnProperty('status') && mA.status !== env.status ) {
                        return false
                    }
                    return true
                }
            })
        },
        formatDate,
        async bulkActioned({key,keyPath,val}){
            let self = this
            if (key === 'delete'){
                this.$confirm({
                    title: `Are you sure you want to delete the selected envelopes? (${self.selectedRowKeys.length} in Total) ?`,
                    okText:'Delete',
                    okType: 'danger',
                    centered: true,
                    onOk() {
                        self.$api.put(`/envelops/:instance/${self.currentTransaction.id}/bulk`, self.selectedRowKeys).then( ({data}) => {
                            self.deletedEnvelope = self.deletedEnvelope.concat(self.selectedRowKeys);
                            self.selectedRowKeys = []
                        }).catch( err => {
                            self.$toastError(err, 'There was an error deleting the files. Please try again.')
                        }).finally( () => {
                            self.$store.commit('LOAD', false)
                        })
                    },
                    class: 'test',
                })
			} else if (key === 'download') {
				this.$store.commit('LOAD', true)
				try {
					const envelops = this.dataTransformed.filter((e => this.selectedRowKeys.includes(e.id)));

					const fileList = [];
					envelops.forEach(e => {
						let { file } = e;
						file.forEach(f => {
							fileList.push({ name: e.name, ...f})
						})
					})

					let fileURLs = [];

					fileList.forEach((file) => {
						fileURLs.push(this.$api.get(`/contract-templates/:instance/getfile?key=${file.key}`).then((({ data }) => data)))
					})

					const urlList = await Promise.all(fileURLs);

					let downloadPromises = [];

					urlList.forEach((url) => {
						//using default axios as wrapper is through cross error
						downloadPromises.push(axios.get(url, { responseType: 'blob' }).then(({ data }) => data))
					})

					const result = await Promise.all(downloadPromises);

					const nameCount = {};
					result.forEach((file, index) => {
						let name = fileList[index].name;

						// If the name already exists in the object, append a counter to make it unique
						if (nameCount[name] !== undefined) {
							nameCount[name] += 1;
							const extensionIndex = name.lastIndexOf('.');
							if (extensionIndex === -1) {
								name = `${name} (${nameCount[name]})`;
							} else {
								name = `${name.slice(0, extensionIndex)} (${nameCount[name]})${name.slice(extensionIndex)}`;
							}
						} else {
							nameCount[name] = 0;
						}

						// Add the file to the zip with the unique name
						this.zip.file(name, file);
					})
					let fileName = `transaction-documents-${this.currentTransaction.id}.zip`
					this.zip.generateAsync({ type: "blob" }).then(function (b) {
						saveAs(b, fileName);
					});
					this.zip = new JSZip()
				} catch (err) {
                    this.$toastError(err, 'Something happened while trying to download selected documents. Please try again.')
				}
				this.$store.commit('LOAD', false)
			} else {
                this.$confirm({
                    title: `Are you sure you want to VOID the selected envelopes? (${self.selectedRowKeys.length} in Total) ?`,
                    okText:'VOID',
                    okType: 'danger',
                    centered: true,
                    onOk() {
                        self.$store.commit('LOAD',true)
                        self.$api.put(`/envelops/${self.$store.state.instance.id}/${self.currentTransaction.id}/void`, self.selectedRowKeys).then( ({data}) => {
                            self.selectedRowKeys = []
                        }).catch((err) => {
                            self.$toastError(err, `An error occurred while voiding. Please try again.`)
                        }).finally( () => {
                            self.$store.commit('LOAD', false)
                        })
                    },
                    class: 'test',
                })
            }
        },
        async action(e){
            if (e.key === 'downloadDoc'){
                this.$store.commit('LOAD',true)
				let index = e.val && e.val.file && e.val.file.length ? (e.val.file.length - 1) : 0
                let key = e.val.file[index].key
                let {data:url} = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`);

                await downloadURL(url, e.val.name)

                setTimeout(() => {
                    this.$store.commit('LOAD',false)
                }, 200);

			} else if (e.key === 'printDoc') {
				this.$store.commit('LOAD', true)
				let index = e.val && e.val.file && e.val.file.length ? (e.val.file.length - 1) : 0
				let key = e.val.file[index].key

				const imageRegex = /^.*\.(jpg|jpeg|png|gif|bmp|svg)$/i;
				if (!imageRegex.test(key)) {
					return this.previewFile(e.val)
				}

				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`);
				this.$store.commit('LOAD', false);

				const printWindow = window.open('', '_blank');
				printWindow.document.write(`
			        <html>
			        <head>
			          <title>Print Document</title>
			        </head>
			        <body>
			          <iframe id="printDoc" src="${url}" style="width: 100%; height: 100%;" frameborder="0"></iframe>
			        </body>
			        </html>
			      `);
				printWindow.document.close();

				// Ensure the PDF has loaded before calling print
				printWindow.onload = function () {
					printWindow.focus();
					printWindow.print();
				};
			} else if (e.key === 'edit'){
                this.$router.push(`/contract/${e.val.id}`)
            } else if (e.key === 'delete'){
                let self = this

                let deleteMessage = e.val.esign && e.val.status !== 'draft' ? 'VOID and DELETE' : 'DELETE'
                this.$confirm({
                    title: deleteMessage,
					content: h => <div>Are you sure you want to {deleteMessage} this Document <strong>{e.val.name}</strong>? This is irreversible!</div>,
                    okText:'DELETE',
                    okType: 'danger',
                    centered: true,
                    onOk() {
                        self.$store.commit('LOAD',true)
                        self.$api.delete(`/envelops/:instance/${e.val.transaction}/${e.val.id}`).then( ({data}) => {
                            self.deletedEnvelope.push(e.val.id)
                        }).catch( err => {
                            self.$toastError(err, 'Something happened while trying to delete this document. Please try again.')
                        }).finally( () => self.$store.commit('LOAD',false))
                    },
                    class: 'test',
                })
            } else if (e.key === 'resend'){

                this.$confirm({
                    title: `This will resend the envelope to all the recipients.`,
                    okText:'RESEND',
                    class: 'test',
                    okType: 'success',
                    centered: true,
                    onOk:() => {
                        this.$store.commit('LOAD',true)
						let url = `/envelops/:instance/${e.val.transaction}/${e.val.id}/resend`
						if (this.$route.meta.reservation) {
							url = `/envelops/:instance/${e.val.id}/resend`
						}
                        this.$api.get(url).then(({data}) => {
                            return this.$message.success('Successfully resent the envelope!')
                        })
                        .catch( err => {
                            this.$toastError(err, 'An error occurred while resending. Please try again!')
                        })
                        .finally( () => this.$store.commit('LOAD',false))
                    },
                })

            } else if (e.key === 'void'){
                let self = this
                this.$confirm({
                    title: `Are you sure you want to VOID this Document (${e.val.name})? This is irreversible!`,
                    okText:'VOID',
                    okType: 'danger',
                    centered: true,
                    onOk() {
                        self.$store.commit('LOAD',true)
                        self.$api.put(`/envelops/:instance/${e.val.transaction}/${e.val.id}`, {status:'voided'}).then( ({data}) => {
                            e.val.status = 'voided'
                        }).catch( err => {
                            self.$toastError(err, 'An error occurred while voiding this document. Please try again!')
                        }).finally( () => self.$store.commit('LOAD',false))
                    },
                    class: 'test',
                })
            } else if (e.key === 'voidTransaction'){

                if (this.$p < 40) return this.$message.error('You do not have permission to void this transaction')

                this.$confirm({
                    title: 'Void Transaction',
                    content: h => <div>Doing this will void this entire transaction? <br/>This will void any documents that are pending to be signed and remove any supporting documents and also removes any restrictions from the <b>underlying asset</b> and revert it's status to <b><em>Available</em></b>.<p class="mt-2">Are you sure you want to continue?</p></div>,
                    okText: 'Continue',
                    okType: 'primary',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk: () => {
						this.$store.commit('LOAD',true)
                        this.$api.put(`/transactions/:instance/${e.val.transaction}/void`).then( ({data}) => {
                            this.$store.commit('SET_PROP', {where:['transactions',e.val.transaction], what:data})
                            this.$router.push('/')
                        }).catch( (err) => {
                            this.$toastError(err, 'An error occurred while voiding the transaction. Please try again!')
                        }).finally( () => this.$store.commit('LOAD',false))
                    }
                })
            } else if (e.key === 'voidReservation'){

				if (this.$p < 40) return this.$message.error('You do not have permission to void this reservation')

				this.reservationCancellation.reason = '';
				this.voidModal = true;
			} else if (e.key === 'uploadSigned'){
                let env = e.val
                let label = env.name
                uploadFile((file) => {
                    this.$confirm({
                        title: 'Confirm Upload',
                        content: h => <div class="my-2">Please confirm that this file is the Signed copy of <strong style="display:inline-block">{label}</strong></div>,
                        okText: 'Continue',
                        okType: 'primary',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk: () => {
							this.$store.commit('SHOW_MANUAL_UPLOAD', {file, buttonText: 'UPLOAD' ,callback: this.uploadSignedFile.bind(this, env, file) })
                        }
                    })
                })
            } else if (e.key === 'downloadManual'){
                let envelope = e.val
                this.downloadFile(envelope,0)
            } else if (e.key === 'preview'){
				this.previewFile(e.val)
            } else if (e.key === 'inPersonSigning') {
				this.inPersonSigning = {
					showModal: true,
					envelope: e.val
				}
			} else if (e.key === 'continueEditing') {
				this.$emit('editEnvelope', e.val)
			}
        },
        getPagesLength(c){
            let pages = JSON.parse(c.pages)
            return pages.length
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },

        async processInPersonSigning() {
            this.$store.commit('LOAD', true)
            try {
                const signer = this.validInPersonSigners.find(s => s.email === this.inPersonSigning.signer)
                if (!signer) {
                    this.$message.error('No valid in-person signer found. Please try again')
                    return
                }

                const { data } = await this.$api.post(`/envelops/:instance/${this.inPersonSigning.envelope.id}/get-in-person-recipient-signing-url`, {
                    returnUrl: window.location.href,
                    signer: signer
                })

                if (data.url) {
                    window.location.href = data.url
                } else {
                    this.$message.error('Failed to retrieve signing URL. Please try again')
                }
            } catch (err) {
                this.$toastError(err, 'Error while processing offline signing. Please try again')
            } finally {
                this.$store.commit('LOAD', false)
                this.closeInPersonSigning()
            }
        },

		closeInPersonSigning() {
			this.inPersonSigning = { envelope: null, signer: null, showModal: false }
		},

		checkSignInPerson(signers) {
			if (!signers) {
				return false;
			}
			return signers.some(s => {
				return s.signers.some(ss => ss.wantToSignInPerson)
			})
		},

		uploadSignedFile(env, file) {
			this.$store.commit('LOAD', true)
			let fD = new FormData()
			fD.append('file', file)

			this.$api.put(`/envelops/:instance/${this.currentTransaction.id}/${env.id}/file`, fD, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
				this.$store.commit('UPDATE_ENVELOPE', data)
				this.updatedEnvelopes[data.id] = data
				this.$store.commit('SET_PROP', { where: ['transactions', this.currentTransaction.id, 'status'], what: 'completed' })
				this.transformData()
			}).catch(err => {
                this.$toastError(err, 'An error occurred while uploading the file. Please try again!')
			}).finally(() => {
				this.$store.commit('LOAD', false)
				this.$store.commit("CLOSE_PREVIEW");
			})
		},

		async voidReservation() {
			let self = this
			this.$refs.reservationCancellation.validate(async (valid) => {
				if (valid) {
					self.loading = true;
					self.$api.post(`reservations/:instance/${self.reservation.id}/void`, { reason: self.reservationCancellation.reason }).then(() => {
						self.$refs.reservationCancellation.resetFields()
						self.voidModal = false;
						self.$message.success('Selected Reservation voided successfully')
						self.$store.dispatch('FETCH_RESERVATION_DETAILS')
					}).catch((err) => {
                        self.$toastError(err, `An error occurred while voiding reservation. Please try again.`)
					}).finally(() => self.loading = false);
				}
			})
		},
    },
    created(){
        this.transformData()

    }
}
</script>

<style lang="scss">
    .downloadable-link-tag{

        position: absolute;
        width:0;
        height:0;
        visibility: hidden;
        pointer-events: none;
        max-width:0px;
        max-height:0px;

    }
    .downloadable-frame{
        display:none;
        opacity:0;
        visibility: hidden;
        pointer-events: none;
        width:1px;
        height:1px;
        position: absolute;
    }
</style>
