<template>
	<div>
		<ScanLicense :options="scanOptions" :whoName="whoName" @close="closeScan" type="dl" />
		<ScanPassport :options="scanOptions" :whoName="whoName" @close="closeScan" />
		<a-modal :okButtonProps="{ disabled: true }" :visible="participantModal.show" :title="participantModal.title"
			class="modal-large" @ok="submitModal" @cancel="resetModal"
			:okText="participantModal.edit ? 'UPDATE' : 'ADD'" cancelText="CANCEL" centered>
			<a-form-model :model="purchaser" ref="purchaser" v-if="participantModal.type === 'purchaser'">
				<a-row>
					<a-form-model-item label="Search for a Contact">
						<searchContact v-if="participantModal.show" @updatecontact="updatePerson" />
					</a-form-model-item>
				</a-row>
				<a-alert message="Use purchaser information as it appears on Government ID." type="warning" show-icon />
				<hr />
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="First Name" prop="firstName" :rules="req(`First Name is required`)">
							<a-input type="text" v-model="purchaser.firstName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Last Name" prop="lastName" :rules="req(`Last Name is required`)">
							<a-input type="text" v-model="purchaser.lastName" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="Middle Name" prop="middleName">
							<a-input type="text" v-model="purchaser.middleName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Date of Birth" prop="dob" :rules="req(`Date of birth is required`)">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.dob"
								:disabled-date="disabledDate" style="width: 100%;"
								valueFormat="YYYY/MM/DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="Email" prop="email"
							:rules="{ validator: isEmail, required: true, message: 'Email is required' }" required>
							<a-input type="text" v-model="purchaser.email" :disabled="selectedContact != ''" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Phone#" prop="phone" :rules="req(`Phone is required`)">
							<a-input type="text" v-model="purchaser.phone" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Business Phone#" prop="business">
							<a-input type="text" v-model="purchaser.business" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Cell Phone#" prop="cell">
							<a-input type="text" v-model="purchaser.cell" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Company" prop="company">
							<a-input type="text" v-model="purchaser.company" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Occupation" prop="occupation">
							<a-input type="text" v-model="purchaser.occupation" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Job Title" prop="jobTitle">
							<a-input type="text" v-model="purchaser.jobTitle" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="SSN/SIN #" prop="ssnsin">
							<a-input type="text" v-model="purchaser.ssnsin" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Driver License #" prop="idValue"
							:rules="purchaser.passportOtherValue ? [] : req(`Driver License # is required`)">
							<a-input type="text" v-model="purchaser.idValue">
								<a-icon @click="addPurchaserModal('editScanLicense')" slot="suffix" type="camera" />
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Issuing Jurisdiction" prop="issuing">
							<a-input type="text" v-model="purchaser.issuing" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Issuing Jurisdiction - Country" prop="issuingCountry">
							<a-input type="text" v-model="purchaser.issuingCountry" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Expiry" prop="expiry">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.expiry" style="width: 100%;"
								valueFormat="YYYY/MM/DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
					<a-col :md="24">
						<a-tabs v-model="activeIdKey">
							<a-tab-pane v-for="(idType,idTypeI) in identificationTypes" :key="idType.value" :tab="idType.label">
								<a-col :md="12" v-if="idType.value == 'other'">
									<a-form-model-item label="Other ID Type Name" prop="otherName">
										<a-input type="text" v-model="purchaser.otherName" />
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item :label="passportOther.valueLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherValue' : idType.value + 'Value'"
									:rules="purchaser.idValue ? [] : req(`${passportOther.valueLabel[idType.value]} is required`)">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherValue' : idType.value + 'Value']">
											<a-icon v-if="idType.value === 'pp'" @click="addPurchaserModal('scanPassport')"
												slot="suffix" type="camera" />
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item :label="passportOther.issuingLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherIssuing' : idType.value + 'Issuing'">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherIssuing' : idType.value + 'Issuing']"/>
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item
										:label="passportOther.issuingCountryLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherIssuingCountry' : idType.value + 'IssuingCountry'">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherIssuingCountry' : idType.value + 'IssuingCountry']" />
									</a-form-model-item>
								</a-col>
								<a-col :md="12" v-if="idType.value != 'bc'">
									<a-form-model-item :label="passportOther.expiryLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherExpiry' : idType.value + 'Expiry'">
										<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser[idType.value == 'pp' ? 'passportOtherExpiry' : idType.value + 'Expiry']"
											style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
									</a-form-model-item>
								</a-col>
								<template v-if="idType.value == 'bc'">
									<a-col :md="12">
										<a-form-model-item label="Birth Place" prop="birthPlace">
											<a-input type="text" v-model="purchaser.birthPlace" />
										</a-form-model-item>
									</a-col>
									<a-col :md="12">
										<a-form-model-item label="Birth Certificate Date of Registration" prop="registrationDate">
											<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.registrationDate"
											style="width: 100%;" valueFormat="YYYY/MM/DD" :disabled-date="disabledDate"></a-date-picker>
										</a-form-model-item>
									</a-col>
									<a-col :md="12">
										<a-form-model-item label="Birth Certificate Date Issued" prop="issueDate">
											<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.issueDate"
											style="width: 100%;" valueFormat="YYYY/MM/DD" :disabled-date="disabledDate"></a-date-picker>
										</a-form-model-item>
									</a-col>
								</template>
							</a-tab-pane>
						</a-tabs>
					</a-col>

					<a-col :span="24" class="mb-3">
						<hr />
					</a-col>

					<a-col :md="12">
						<a-form-model-item label="Address 1" prop="address1" :rules="req(`Address is required`)">
							<a-input type="text" v-model="purchaser.address1" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Address 2" prop="address2">
							<a-input type="text" v-model="purchaser.address2" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="City" prop="city" :rules="req(`City is required`)">
							<a-input type="text" v-model="purchaser.city" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="State/Province" prop="region"
							:rules="req(`State/Province is required`)">
							<a-input type="text" v-model="purchaser.region" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Zip/Postal Code" prop="postal" :rules="req(`Zip/Postal is required`)">
							<a-input type="text" v-model="purchaser.postal" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Country" prop="country" :rules="req(`Country is required`)">
							<a-input type="text" v-model="purchaser.country" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>

			<p class="text-danger" v-if="participantModal.errorMsg">{{ participantModal.errorMsg }}</p>
		</a-modal>

		<a-row :gutter="32">
			<a-col :span="24">
				<h5>{{ whoName }}(s)</h5>
				<p v-if="purchaserNotAdded && purchasers.length === 0" class="text-danger">Must add atleast one
					{{ whoName }} to continue</p>
				<a-divider class="mb-0" />

				<!-- LIST OF ADDED PURCHASERS -->
				<ParticipantCard v-for="buyer in purchasersSummarized" :amendmentDoc="true" :key="buyer.id" :item="buyer"
					@edit="editPurchaser" @trash="(e) => editPurchaser(e, true)" />

				<!-- ADD NEW PURCHASER BUTTON -->
				<a-dropdown :trigger="['click']" style="width:auto" v-if="maxPurchasers > purchasers.length">
					<div
						class="dF py-4 aC w-full purchaser-side-add-card cursor-pointer hover:text-primary no-selection-parent">
						<div class="add-circle-dashed">
							<i class="fe fe-plus" />
						</div>
						<div class="f1 pl-4">
							Add {{ purchaserLabel[purchasers.length] || `${this.whoName} ${purchasers.length + 1}` }}
						</div>
					</div>

					<a-menu slot="overlay" @click="addPurchaserModal">
						<a-menu-item key="scan">
							<i class="fe fe-camera" style="width:17px;display:inline-block" /> Scan Driver's License
						</a-menu-item>
						<a-menu-item key="scanPassport">
							<i class="fe fe-camera" style="width:17px;display:inline-block" /> Scan Passport
						</a-menu-item>
						<a-menu-item key="manual">
							<i class="fe fe-plus" style="width:17px;display:inline-block" /> Create Manually
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { validateEmail } from 'bh-mod'
import ParticipantCard from '@/components/transaction/ParticipantCard'
import moment from 'moment'
import searchContact from '@/components/common/searchContact'
import ScanLicense from '@/components/common/scanLicense.vue'
import ScanPassport from '@/components/common/scanPassport.vue'

let isEmail = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('Email is required'))
	} else if (!validateEmail(value)) {
		callback(new Error('Email is not valid'))
	}
	callback()
}

export default {
	components: { ParticipantCard, searchContact, ScanLicense, ScanPassport },
	props: ['transaction', 'contract'],
	data: () => ({
		scanOptions: {
			showLicenseScan: false,
			showPassportScan: false
		},
		activeIdKey:'pp',
		manualModal: false,
		selectedContact: '',
		showScanError: false,
		purchaserNotAdded: false,
		purchasers: [],
		purchaserScan: {
			errorFront: false,
			errorBack: false,
			fileFront: null,
			fileBack: null,
			data: {},
		},
		isEmail,
		participantModal: {
			show: false,
			edit: false,
			type: 'purchaser',
			title: '',
			id: '',
			errorMsg: '',
		},
		purchaser: {
			id: '',
			firstName: '',
			middleName: '',
			lastName: '',
			email: '',
			phone: '',
			dob: '',
			business: '',
			cell: '',
			issuing: '',
			issuingCountry: '',
			expiry: '',
			company: '',
			occupation: '',
			idType: 'pp',
			idValue: '',
			passportOtherValue: '',
			passportOtherIssuing: '',
			passportOtherIssuingCountry: '',
			passportOtherExpiry: '',
			prValue: '',
			prIssuing: '',
			prIssuingCountry: '',
			prExpiry: '',
			wpValue: '',
			wpIssuing: '',
			wpIssuingCountry: '',
			wpExpiry: '',
			bcValue: '',
			bcIssuing: '',
			bcIssuingCountry: '',
			otherName: '',
			otherValue: '',
			otherIssuing: '',
			otherIssuingCountry: '',
			otherExpiry: '',
            birthPlace: '',
            registrationDate: '',
            issueDate: '',
			address1: '',
			address2: '',
			postal: '',
			country: '',
			region: '',
			city: '',
			jobTitle: '',
			ssnsin: '',
		},
		maxPurchasers: 1
	}),

	computed: {
		purchaserLabel() {
			return this.transaction.type === 'lease' ? ['Primary Tenant', 'Secondary Tenant'] : ['Primary Purchaser', 'Secondary Purchaser']
		},
		whoName() {
			return this.transaction.type === 'lease' ? 'Tenant' : 'Purchaser'
		},

		purchasersSummarized() {
			return this.purchasers.map((x, xI) => {
				return {
					name: `${x.firstName} ${x.lastName}`,
					description: (this.purchaserLabel[xI] || `${this.whoName} ${xI + 1}`) + ` (${x.email.toLowerCase()})`,
					id: x.id,
					invalid: !(x.idValue.length || x.passportOtherValue.length)
				}
			})
		},

		identificationTypes() {
			return this.$store.state.appData.identificationTypes
		},

		passportOther() {
			return this.$store.state.appData.passportOther
		},
	},
	watch: {
		'purchaser.idType'() {
			this.$refs.purchaser?.clearValidate('passportOtherValue')
		},
		'purchaser.idValue'() {
			this.$refs.purchaser?.clearValidate('passportOtherValue')
		},
		'purchaser.passportOtherValue'() {
			this.$refs.purchaser?.clearValidate('idValue')
		},
	},
	methods: {
		closeScan() {
			this.scanOptions = {
				showLicenseScan: false,
				showPassportScan: false
			}
		},
		updatePerson(contact) {
			this.selectedContact = contact
			this.$store.commit('LOAD', true)
			this.$api.get(`/contacts/:instance/${contact}`).then(({ data }) => {
				this.purchaser = {
					id: this.purchaser.id,
					firstName: data.firstName || this.purchaser.firstName || '',
					middleName: data.middleName || this.purchaser.middleName || '',
					lastName: data.lastName || this.purchaser.lastName || '',
					email: data.email,
					phone: data.phone || '',
					company: data.company || '',
					occupation: data.occupation || '',
					business: '',
					cell: '',
					dob: data.dob || this.purchaser.dob,
					issuing: '',
					issuingCountry: '',
					expiry: '',
					idType: 'pp',
					idValue: this.purchaser.idValue || '',
					passportOtherValue: this.purchaser.passportOtherValue || '',
					passportOtherIssuing: '',
					passportOtherIssuingCountry: '',
					passportOtherExpiry: '',
					prValue: '',
					prIssuing: '',
					prIssuingCountry: '',
					prExpiry: '',
					wpValue: '',
					wpIssuing: '',
					wpIssuingCountry: '',
					wpExpiry: '',
					bcValue: '',
					bcIssuing: '',
					bcIssuingCountry: '',
					otherName: '',
					otherValue: '',
					otherIssuing: '',
					otherIssuingCountry: '',
					otherExpiry: '',
                    birthPlace: '',
                    registrationDate: '',
                    issueDate: '',
					address1: data.address || '',
					address2: data.address2 || '',
					postal: data.postal || '',
					country: data.country == 'Select a country' ? '' : data.country,
					region: data.region,
					city: data.city,
					jobTitle: data.jobTitle,
					ssnsin: data.ssnsin || '',
				}
				this.$store.commit('LOAD', false)
			}).catch((err) => {
				this.$store.commit('LOAD', false)
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},

		submitModal() {
			this.manualModal = false
			return this.createParticipant()
		},
		disabledDate(current) {
			return current && current > moment().startOf('day')
		},

		editPurchaser(id = '', del = false) {
			if (!id) return null

			let idx = this.purchasers.findIndex(x => x.id === id)
			if (idx === -1) return null

			let self = this

			let purchaser = this.purchasers[idx]
			let pLabel = this.purchaserLabel[idx] || `${this.whoName} ${idx + 1}`

			if (del) {
				return this.$confirm({
					title: `Are you sure you want to delete ${this.whoName} #${idx + 1}:  ${purchaser.firstName} ${purchaser.lastName} ?`,
					okText: 'Delete',
					okType: 'danger',
					centered: true,
					onOk() {
						self.purchasers.splice(idx, 1)
					},
					onCancel() {
					},
				})

			}
			this.manualModal = true
			this.participantModal = {
				show: true,
				edit: true,
				title: 'Edit ' + pLabel,
				type: 'purchaser',
				id,
				errorMsg: '',
			}
			this.purchaser = { ...purchaser, idType: purchaser.idType === 'dl' ? 'pp' : purchaser.idType }


		},
		req: msg => ({ required: true, message: msg }),
		resetModal() {
			this.manualModal = false
			if (this.$refs.purchaser) {
				this.$refs.purchaser.resetFields()
				this.purchaser = {
					id: '',
					firstName: '',
					middleName: '',
					lastName: '',
					email: '',
					phone: '',
					dob: '',
					issuing: '',
					issuingCountry: '',
					expiry: '',
					company: '',
					occupation: '',
					business: '',
					cell: '',
					idType: 'pp',
					idValue: '',
					passportOtherValue: '',
					passportOtherIssuing: '',
					passportOtherIssuingCountry: '',
					passportOtherExpiry: '',
					prValue: '',
					prIssuing: '',
					prIssuingCountry: '',
					prExpiry: '',
					wpValue: '',
					wpIssuing: '',
					wpIssuingCountry: '',
					wpExpiry: '',
					bcValue: '',
					bcIssuing: '',
					bcIssuingCountry: '',
					otherName: '',
					otherValue: '',
					otherIssuing: '',
					otherIssuingCountry: '',
					otherExpiry: '',
                    birthPlace: '',
                    registrationDate: '',
                    issueDate: '',
					address1: '',
					address2: '',
					postal: '',
					country: '',
					region: '',
					city: '',
					jobTitle: '',
					ssnsin: ''
				}
			}

			this.participantModal = {
				show: false,
				edit: false,
				type: 'purchaser',
				title: '',
				id: '',
				errorMsg: '',
			}

			this.showScanError = false
			this.selectedContact = ''
			this.activeIdKey = 'pp'
			this.purchaserScan = {
				errorFront: false,
				errorBack: false,
				fileFront: null,
				fileBack: null,
				data: {}
			}
		},
		createParticipant() {
			let self = this
			if (this.participantModal.type === 'purchaser') {
				this.$refs.purchaser.validate(valid => {
					if (valid) {
						let newP = { ...self.purchaser }
						let firstName = newP.firstName
						let lastName = newP.lastName
						if (newP.middleName && newP.middleName.trim()) lastName = newP.middleName.trim() + ' ' + lastName

						newP.fullName = `${firstName} ${lastName}`
						newP.address = `${newP.address1}${newP.address2 ? ', ' + newP.address2 : ''}`;
						newP.cityRegionPostalZip = `${newP.city}, ${newP.region}, ${newP.postal}`;
						newP.fullAddress = `${newP.address1}, ${newP.address2}, ${newP.city}, ${newP.region}, ${newP.postal}`.replaceAll(', , ', ', ').trim();

						if (newP.idValue && newP.idValue != '') {
							newP.licenseIdType = "Driver's License"
						} else {
							newP.licenseIdType = ""
						}

                        if (newP.passportOtherValue) {
                            newP.passportOtherIdType = 'Passport';
                        } else {
                            newP.passportOtherIdType = '';
                        }

						if (newP.prValue) {
                            newP.prIdType = 'PR';
                        } else {
                            newP.prIdType = '';
                        }

						if (newP.wpValue) {
                            newP.wpIdType = 'Work Permit';
                        } else {
                            newP.wpIdType = '';
                        }

						if (newP.bcValue) {
                            newP.bcIdType = 'Birth Certificate';
                        } else {
                            newP.bcIdType = '';
                        }

						if (newP.otherValue) {
                            newP.otherName = newP.otherName;
                        } else {
                            newP.otherName = '';
                        }

						let id = Date.now();

						if (self.participantModal.edit) {
							let found = self.purchasers.find(p => p.email === newP.email && p.id !== newP.id);
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser'
							found = self.purchasers.find(p => newP.idValue && (p.idValue === newP.idValue) && p.id !== newP.id)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same driver license number.'
							found = self.purchasers.find(p => newP.passportOtherValue && (p.passportOtherValue === newP.passportOtherValue) && p.id !== newP.id)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same passport number.'
							self.purchasers = self.purchasers.map(x => x.id === newP.id ? newP : x)
						} else {
							let found = self.purchasers.find(x => x.email === newP.email)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same email address.'
							found = self.purchasers.find(x => newP.idValue && (x.idValue === newP.idValue))
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same driver license number.'
							found = self.purchasers.find(x => newP.passportOtherValue && (x.passportOtherValue === newP.passportOtherValue))
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same passport number.'
							newP.id = id
							self.purchasers.push(newP)
						}

						self.participantModal.errorMsg = false

						self.resetModal()

					} else {
						return false;
					}
				})
			}
		},
		addPurchaserModal(e) {

			let pLabel = this.purchaserLabel[this.purchasers.length] || `${this.whoName} ${this.purchasers.length + 1}`

			if (e === 'editScanLicense') {
				this.scanOptions = {
					showLicenseScan: true,
					callback: purchaser => {

						this.purchaser = {
							...purchaser,
							amended: true,
							email: this.purchaser.email || '',
							phone: this.purchaser.phone || '',
						}

					},
				}
			} else if (e.key === 'scanPassport' || e === 'scanPassport') {
				this.scanOptions = {
					showPassportScan: true,
					callback: purchaser => {
						this.purchaser = {
							...this.purchaser,
							...purchaser,
							amended: true,
						}

						if (e.key === 'scanPassport') {
							this.participantModal = {
								show: true,
								edit: false,
								title: 'Add ' + pLabel,
								type: 'purchaser',
								id: '',
								errorMsg: '',
							}
						}
					},
				}
			} else if (e.key === 'manual') {
				this.participantModal = {
					show: true,
					edit: false,
					title: 'Add ' + pLabel,
					type: 'purchaser',
					id: '',
					errorMsg: '',
				}
				this.purchaser.idType = 'pp'
				this.purchaser.country = 'Canada'
				this.purchaser.region = 'Ontario'
			} else {
				this.scanOptions = {
					showLicenseScan: true,
					callback: purchaser => {
						this.purchaser = {
							...purchaser,
							amended: true,
						}

						this.participantModal = {
							show: true,
							edit: false,
							title: 'Add ' + pLabel,
							type: 'purchaser',
							id: '',
							errorMsg: '',
						}
					},
				}
			}
		},
		async validate() {
			if (!this.purchasers.length) return this.purchaserNotAdded = true;
			this.$store.commit('LOAD', true);

			for (let index = 0; index < this.purchasers.length; index++) {
				const purchaser = this.purchasers[index];
				if (purchaser.fileFront && typeof purchaser.fileFront === 'object' && purchaser.fileBack && typeof purchaser.fileBack === 'object' && !purchaser.fileFront.url && !purchaser.fileBack.url) {
					let result = await Promise.all([this.uploadFile(purchaser.fileFront), this.uploadFile(purchaser.fileBack)]);
					this.purchasers[index].fileFront = result && result[0];
					this.purchasers[index].fileBack = result && result[1];
				}
				if (purchaser.filePassport && typeof purchaser.filePassport === 'object' && !purchaser.filePassport.url) {
					let result = await this.uploadFile(purchaser.filePassport);
					this.purchasers[index].filePassport = result;
				}
			}

			this.$store.commit('LOAD', false);

			this.purchaserNotAdded = false
			let sendOBJ = {
				purchasers: this.purchasers,
			}

			this.$emit('done', sendOBJ)
		},

		async uploadFile(file) {
			const formData = new FormData();
			formData.append('files', file, file.name);
			formData.append('force', 1);
			formData.append('isPrivate', 1);
			formData.append('path', `${this.whoName} Documents`);
			formData.append('instance', this.$store.state.instance.id);

			try {
				const { data } = await this.$api.post('/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				return data && data[0];
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying to get draft transaction details. Please try again.`))
				}
				return null
			}
		},
	},
	async created() {
		if (this.transaction?.purchasers?.length) {
			this.purchasers = JSON.parse(JSON.stringify(this.transaction.purchasers))
		}
	},
	mounted() {
		this.$emit('setFn', this.validate)

		let { pages = [] } = this.contract;

		pages.forEach(page => {
			let { fields = [] } = page
			fields.forEach(spot => {
				let { field, text } = spot
				if (field.value.includes('purchasers[]') && text.purchasers > this.maxPurchasers) {
					this.maxPurchasers = text.purchasers
				}
			})
		})
	}
}
</script>

<style lang="scss">
.add-circle-dashed,
.participant-icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px dashed currentColor;
	display: flex;
	place-items: center;
	place-content: center;
	font-size: 20px;
	color: currentColor;
}

.participant-icon {
	border: 1px solid currentColor;
}

.purchaser-side-add-card {
	border-bottom: 1px solid var(--light-purple);
	color: var(--med-gray);

	&:hover {
		color: var(--primary);
	}
}
</style>
